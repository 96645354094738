import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import {
  B2LightBlue,
  Btn1Blue,
  H1Blue,
  standardWrapper,
} from "../../../styles/helpers"

const SimpleContentButton = ({ data }) => {
  return (
    <SectionStyled>
      <div className="wrapper">
        <div className="title">
          <h2>{data.simpleContentBtnTitle}</h2>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.simpleContentBtnParagraph }}
        />
        {data.simpleContentWithButtonRequired && (
          <div className="btn-link">
            <Link to={`/${data.simpleContentBtnSlug}`}>
              {data.simpleContentBtnText}
            </Link>
          </div>
        )}
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  padding: 10rem 0 2.5rem;

  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${H1Blue};
      margin-bottom: 3rem;
    }
  }

  .content {
    width: 100%;
    max-width: 90rem;
    margin: auto;
    text-align: center;

    p {
      ${B2LightBlue};
    }
  }

  .btn-link {
    width: 100%;
    margin-top: 5rem;
    text-align: center;

    a {
      ${Btn1Blue};
    }
  }
`

export default SimpleContentButton
